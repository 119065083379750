export default [
  {
    name: "Ecosystem",
    items: [
      { name: "Home", link: "/" },
      { name: "Governance", link: "https://snapshot.org/#/gton.eth" },
      // { name: "Bridge", link: "/bridge" },
      { name: "Apps", link: "/apps" },
      {
        name: "Help Center",
        link: "https://discord.com/channels/881824930822512660/884746114065125417",
      },
    ],
  },
  {
    name: "Developers",
    items: [
      { name: "Documentation", link: "https://docs.gton.capital" },
      {
        name: "Block explorer",
        link: "https://explorer.gton.network/",
      },
      // { name: "Status", link: "/" },
      // { name: "Bug bounty", link: "/" },
      // { name: "Marketing support", link: "/" },
      // { name: "Feature request", link: "/" },
      // { name: "Get in touch", link: "/" },
      { name: "GitHub", link: "https://github.com/GTON-capital" },
    ],
  },
  {
    name: "Community",
    items: [
      { name: "Discord", link: "https://discord.com/invite/Ce972zqGW6" },
      { name: "Twitter", link: "https://twitter.com/GtonCapital" },
      // { name: "Twitch", link: "/" },
      { name: "Blog", link: "https://blog.gton.capital" },
      // { name: "Events", link: "/" },
    ],
  },
  {
    name: "About",
    items: [
      { name: "About", link: "/" },
      // { name: "OP Labs careers", link: "/" },
      // { name: "Foundation careers", link: "/" },
      {
        name: "Brand assets",
        link: "https://drive.google.com/drive/folders/1PK-Zzoa07dE6x_pFagALndTIXGcc2N9s?usp=sharing",
      },
      // { name: "On-chain data", link: "/" },
      {
        name: "Privacy policy",
        link: "https://docs.gton.capital/community/legal-disclaimer",
      },
    ],
  },
];
