import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import deviceApple from "./utils/device/deviceApple";
import supportWebp from "./utils/device/supportWebp";
import VueGtag from "vue-gtag";
import "swiper/css";
import "./styles/index.scss";

deviceApple();
supportWebp();

const app = createApp(App);
const pinia = createPinia();

app.use(router);
app.use(pinia);

app.mount("#app");

app.use(VueGtag, {
  config: { id: "G-E0MS1ELP9B" },
});
