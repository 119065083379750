import Vue from "vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const DEFAULT_TITLE = "GTON";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    meta: {
      title: "GTON | Home",
      description: "New generation of programmable economy",
    },
  },
  {
    path: "/apps",
    name: "apps",
    component: () => import("../views/AppsView.vue"),
    meta: {
      title: "GTON | Apps",
      description: "The apps and integrations in the GTON ecosystem.",
    },
  },
  // {
  //   path: "/bridge",
  //   name: "bridge",
  //   component: () => import("../views/BridgeView.vue"),
  //   meta: {
  //     title: "GTON | Bridge",
  //     description: "Gton Capital bridge",
  //   },
  // },
  {
    path: "/academy",
    name: "academy",
    component: () => import("../views/AcademyView.vue"),
    meta: {
      title: "GTON | Academy",
      description: "An educational section on gton.capital",
    },
  },
  {
    path: "/academy/:id",
    name: "academy-post",
    component: () => import("../views/mockup/AcademyPostView.vue"),
    meta: {
      title: "GTON | Academy",
      description: "Post...",
    },
  },
  {
    path: "/get-started",
    name: "getStarted",
    component: () => import("../views/GetStartedView.vue"),
    meta: {
      title: "GTON | Get Started",
      description: "Gton Capital Get started",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/apps",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  document.title = (to.meta.title as string) || DEFAULT_TITLE;
});

export default router;
