export interface HeaderLink {
  icon: string;
  text: string;
  to?: string;
  href?: string;
}

type HeaderGroup = {
  name: string;
  items: HeaderLink[];
};

export default [
  {
    name: "Ecosystem",
    items: [
      {
        icon: "flash.svg",
        text: "Get Started",
        to: "/get-started",
      },
      {
        icon: "apps.svg",
        text: "App",
        to: "/apps",
      },
      // {
      //   icon: "bridge.svg",
      //   text: "Bridge",
      //   to: "/bridge",
      // },
    ],
  },
  {
    name: "Developers",
    items: [
      {
        icon: "share.svg",
        text: "Explorer",
        href: "https://explorer.gton.network/",
      },
      {
        icon: "document.svg",
        text: "Docs",
        href: "https://docs.gton.capital/",
      },
      {
        icon: "github.svg",
        text: "Github",
        href: "https://github.com/GTON-capital",
      },
    ],
  },
  {
    name: "Community",
    items: [
      {
        icon: "flash.svg",
        text: "Snapshot",
        href: "https://snapshot.org/#/gton.eth",
      },
      {
        icon: "discord.svg",
        text: "discord",
        href: "https://discord.com/invite/Ce972zqGW6",
      },
      {
        icon: "telegram.svg",
        text: "Telegram",
        href: "https://t.me/GTON_Capital_official",
      },
      {
        icon: "forum.svg",
        text: "Forum",
        href: "https://forum.gton.capital/latest",
      },
      // { icon: "reddit.svg", text: "Reddit" },
      {
        icon: "twitter.svg",
        text: "Twitter",
        href: "https://twitter.com/GtonCapital",
      },
    ],
  },
  {
    name: "More",
    items: [
      {
        icon: "academy.svg",
        text: "Academy",
        to: "/academy",
      },
      { icon: "blog.svg", text: "Blog", href: "https://blog.gton.capital" },
      // { icon: "twitch.svg", text: "Twitch" },
      {
        icon: "youtube.svg",
        text: "YouTube",
        href: "https://www.youtube.com/channel/UC_bq0AoqZHaBhYsw3sMIlGwl",
      },
    ],
  },
] as HeaderGroup[];
