interface MMChains {
  [key: string]: MMChain;
}

interface NativeCurrency {
  name: string;
  decimals: number;
  symbol: string;
}

interface MMChain {
  chainId: string | number;
  chainIdHex: string;
  chainName: string;
  rpcUrls: Array<string>;
  nativeCurrency: NativeCurrency;
  blockExplorerUrls: Array<string>;
}

export const chains: MMChains = {
  ethereum: {
    chainId: '1',
    chainIdHex: '0x1',
    chainName: 'Ethereum',
    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
    blockExplorerUrls: ['https://etherscan.io'],
  },
  gton: {
    chainId: '1000',
    chainIdHex: '0x3E8',
    chainName: 'GTON Mainnet',
    rpcUrls: ['https://rpc.gton.network/'],
    nativeCurrency: { name: 'GCD', decimals: 18, symbol: 'GCD' },
    blockExplorerUrls: ['https://explorer.gton.network/'],
  },
};
