<template>
  <div :class="$style.socials">
    <div :class="$style.socialsList">
      <a
        :class="$style.socialsItem"
        href="https://twitter.com/GtonCapital"
        target="_blank"
      >
        <img src="/img/socials/twitter.svg" alt="twitter" />
      </a>
      <a
        :class="$style.socialsItem"
        href="https://instagram.com/gtoncapital"
        target="_blank"
      >
        <img src="/img/socials/instagram.svg" alt="instagram" />
      </a>
      <a
        :class="$style.socialsItem"
        href="https://discord.gg/9WRYK4wVxw"
        target="_blank"
      >
        <img src="/img/socials/discord.svg" alt="discord" />
      </a>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style module lang="scss">
.socials {
}
.socialsList {
  display: flex;
  align-items: center;
  margin: 0 -0.84375rem;
}
.socialsItem {
  margin: 0 0.84375rem;
  width: 1.1875rem;
  flex-shrink: 0;
  opacity: 0.7;
  transition: opacity 0.1s ease-out;

  img {
    width: 100%;
  }

  &:hover {
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .socialsItem {
    opacity: 1;
  }
}
</style>
