import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import { bridgeAddressL1, bridgeAddressL2 } from './config';
import BRIDGE from './ABI/BRIDGE.json';
import Big from 'big.js';
import { providers } from 'ethers';
import { useMetamaskStore } from '@/store/useMetamaskStore';

declare const window: any;

export const bridgeToL2 = async (
  userAddress: string,
  tokenAddressL1: string,
  tokenAmount: Big,
  tokenAddressL2: string,
): Promise<string> => {
  // const gcdAddressL2 = '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000';
  const depositErc20 = '2000000000000000';
  const CHAIN_ID = '1000'; // Mainnet
  const GAS = 0;
  const DATA = '0x00';
  try {
    const { web3 } = useMetamaskStore();
    const contract = new web3.eth.Contract(
      BRIDGE as AbiItem[],
      bridgeAddressL1,
    );
    let txn = await contract.methods
      .depositERC20ByChainId(
        CHAIN_ID,
        tokenAddressL1,
        tokenAddressL2,
        tokenAmount.toFixed(),
        GAS,
        DATA,
      )
      .send({ from: userAddress, value: depositErc20 });
    return txn.transactionHash;
  } catch (e) {
    console.log(e);
    //@ts-ignore
    throw new Error(e);
  }
};
