import { defineStore } from "pinia";
import { onMounted, onUnmounted, ref, computed } from "vue";
import { ethers } from "ethers";
import { connect as connectWallet } from "@/WEB3/useMetamaskWallet";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
// import { validate } from '@/WEB3/validate';
import Big from "big.js";
import { AbiItem } from "web3-utils";

import ERC20_ABI from "../WEB3/ABI/ERC20.json";
import { bridgeToL2 } from "@/WEB3/Bridge";
import { TokenItem } from "@/data/tokens";
import { chains } from "@/WEB3/chains";

// Wallet Connection and Utility functions

const isMetaMask: boolean =
  typeof window.ethereum !== "undefined" && window.ethereum.isMetaMask;

export const useMetamaskStore = defineStore("metamask", () => {
  const provider = ref("");
  const address = ref("");
  const balance = ref(0);
  const error = ref("");
  const isApproved = ref(false);
  const isProcessing = ref(false);
  const txHash = ref("");

  const web3 = computed(() => {
    return provider.value
      ? new Web3(provider.value)
      : new Web3(window.ethereum);
  });
  const setAddress = (newAddress: string) => (address.value = newAddress);
  const setError = (newError: string) => (error.value = newError);
  const setBalance = (newBalance: number) => (balance.value = newBalance);
  const setProvider = (newProvider: any) => (provider.value = newProvider);

  const connect = async () => {
    const connectWalletResult = await connectWallet();

    if (Array.isArray(connectWalletResult)) {
      // metamask connected. get first account
      setAddress(connectWalletResult[0]);
      getBallance();
      setError("");
    } else {
      // handle exception cases
      // trigger snackbar
      setError(connectWalletResult);
    }
  };

  const mobileConnect = async () => {
    let provider = new WalletConnectProvider({
      infuraId: "9aa3d95b3bc440fa88ea12eaa4456161", // should be private, but our project is closed
      qrcodeModalOptions: {
        mobileLinks: ["metamask"],
      },
    });
    setProvider(provider);
    //  Enable session (triggers QR Code modal)
    await provider.enable();
    // TODO: add rules only for eth chainID
    if (provider.accounts[0]) {
      setAddress(provider.accounts[0]);
      getBallance();
      setError("");
    } else {
      // handle exception cases
      // trigger snackbar
      setError(provider.accounts[0]);
    }
  };

  const getBallance = async (
    tokenAddress: string = "0x01e0E2e61f554eCAaeC0cC933E739Ad90f24a86d"
  ) => {
    // await validate();
    const contract = new web3.value.eth.Contract(
      ERC20_ABI as AbiItem[],
      tokenAddress
    );

    const userBalance: string = await contract.methods
      .balanceOf(address.value)
      .call();
    const balance = ethers.utils.formatEther(userBalance);
    setBalance(Big(balance).toNumber());
  };

  const getNativeBalance = async () => {};

  const getAccounts = async () => {
    if (isMetaMask) {
      try {
        const accounts: string[] = await window.ethereum.request({
          method: "eth_accounts",
        });
        setAddress(accounts[0]);
        getBallance();
      } catch (e: any) {
        return "Error: Unable to execute request: " + e.message;
      }
    } else {
      return "Error: MetaMask not detected";
    }
  };

  const approve = async (
    token: string,
    spender: string,
    amount: Big
  ): Promise<string | undefined> => {
    // await validate();
    const contract = new web3.value.eth.Contract(ERC20_ABI as AbiItem[], token);
    isProcessing.value = true;
    let tx;
    try {
      tx = await contract.methods
        .approve(spender, amount.toFixed())
        .send({ from: address.value });
    } catch (err) {
      console.log("Approve rejected");
    } finally {
      isProcessing.value = false;
    }

    if (tx) {
      console.log("=== approved ===");
      isApproved.value = true;
      return tx.transactionHash;
    }
  };

  const allowance = async (token: string, spender: string): Promise<Big> => {
    // await validate();
    const contract = new web3.value.eth.Contract(ERC20_ABI as AbiItem[], token);
    const approvedValue: string = await contract.methods
      // track if allows can return error back
      .allowance(address.value, spender)
      .call();

    if (!isApproved.value && Big(approvedValue).toNumber() > 0) {
      isApproved.value = true;
    }

    return Big(approvedValue);
  };

  const bridge = async (token: TokenItem, amount: Big) => {
    isProcessing.value = true;
    try {
      txHash.value = await bridgeToL2(
        address.value,
        token.addressL1,
        amount,
        token.addressL2
      );
    } finally {
      isProcessing.value = false;
    }
  };

  const checkEthChain = async () => {
    const chainId: number = await web3.value.eth.getChainId();
    return chainId === 1;
  };

  const switchChain = async (network: "gton" | "ethereum"): Promise<void> => {
    // ethereum for deposit, gton for withdraw

    const {
      chainIdHex,
      chainName,
      rpcUrls,
      nativeCurrency,
      blockExplorerUrls,
    } = chains[network];

    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: chainIdHex,
          },
        ],
      });
    } catch (err: any) {
      if (err.code === 4902) {
        // 4902 is the error code for attempting to switch to an unrecognized chainId
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: chainIdHex,
                chainName,
                rpcUrls,
                nativeCurrency,
                blockExplorerUrls,
              },
            ],
          });
        } catch (err: any) {
          try {
            await window.ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [
                {
                  chainId: chainIdHex,
                },
              ],
            });
          } catch (err: any) {
            throw new Error(err.message);
          }
        }
      } else {
        throw new Error(err.message);
      }
    }
  };

  const onChainChanged = (callback: (chainId: number) => void) => {
    if (isMetaMask) {
      onMounted(() => {
        window.ethereum.on("chainChanged", callback);
      });
      onUnmounted(() => {
        window.ethereum.removeListener("chainChanged", callback);
      });
    }
  };

  return {
    address,
    allowance,
    setAddress,
    balance,
    provider,
    txHash,
    switchChain,
    onChainChanged,
    checkEthChain,
    isProcessing,
    getBallance,
    getAccounts,
    approve,
    bridge,
    isApproved,
    error,
    connect,
    mobileConnect,
    web3,
  };
});
